import { createAction, props } from '@ngrx/store';
import { Account } from '../../models/account.model';
import { APIError } from '../../models/error.model';

export enum UsersActionsTypes {
    UpdateUser = '[Account Profile Page] Update User',
    UpdateUserSuccess = '[Account Profile Page] Update User Success',
    UpdateUserFailure = '[Account Profile Page] Update User Failure'
}

export const updateUser = createAction(
    UsersActionsTypes.UpdateUser,
    props<{
        payload: any;
    }>()
);
export const updateUserSuccess = createAction(
    UsersActionsTypes.UpdateUserSuccess,
    props<{ payload: Account }>()
);
export const updateUserFailure = createAction(
    UsersActionsTypes.UpdateUserFailure,
    props<{ payload: APIError }>()
);
