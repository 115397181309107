import { createAction } from '@ngrx/store';

export enum VideoGuidesLoadingActionsTypes {
    RecentVideoGuidesLoading = '[Video Guides Effects] Recent Video Guides Loading',
    RecentVideoGuidesLoadingFinished = '[Video Guides Effects] Recent Video Guides Loading Finished',

    SearchVideoGuidesLoading = '[Video Guides Effects] Search Video Guides Loading',
    SearchVideoGuidesLoadingFinished = '[Video Guides Effects] Search Video Guides Loading Finished'
}

export enum CoursesLoadingActionsTypes {
    FeaturedCoursesLoading = '[Courses Effects] Featured Courses Loading',
    FeaturedCoursesLoadingFinished = '[Courses Effects] Featured Courses Loading Finished',

    CoursesLoading = '[Courses Effects] Courses Loading',
    CoursesLoadingFinished = '[Courses Effects] Courses Loading Finished',

    EnrolledCoursesLoading = '[Courses Effects] Enrolled Courses Loading',
    EnrolledCoursesLoadingFinished = '[Courses Effects] Enrolled Courses Loading Finished',

    CourseLoading = '[Courses Effects] Course Loading',
    CourseLoadingFinished = '[Courses Effects] Course Loading Finished'
}

export enum AuthLoadingActionsTypes {
    LoginUserLoading = '[Auth Effects] Loading User Loading',
    LoginUserLoadingFinished = '[Auth Effects] Login User Loading Finished',

    SignupUserLoading = '[Auth Effects] Signup User Loading',
    SignupUserLoadingFinished = '[Auth Effects] Signup User Loading Finished',

    PasswordResetInitiateLoading = '[Auth Effects] Password Reset Initiate Loading',
    PasswordResetInitiateLoadingFinished = '[Auth Effects] Password Reset Initiate Loading Finished',

    PasswordResetLoading = '[Auth Effects] Password Reset Loading',
    PasswordResetLoadingFinished = '[Auth Effects] Password Reset Loading Finished'
}

export enum PaymentLoadingActionsTypes {
    NewSubscriptionLoading = '[Payment Effects] New Subscription Loading',
    NewSubscriptionLoadingFinished = '[Payment Effects] New Subscription Loading Finished',

    AddPaymentMethodLoading = '[Payment Effects] Add Payment Method Loading',
    AddPaymentMethodLoadingFinished = '[Payment Effects] Add Payment Method Loading Finished',

    LoadPaymentMethodsLoading = '[Payment Effects] Load Payment Methods Loading',
    LoadPaymentMethodsLoadingFinished = '[Payment Effects] Load Payment Methods Loading Finished',

    UpdatePaymentMethodLoading = '[Payment Effects] Update Payment Method Loading',
    UpdatePaymentMethodLoadingFinished = '[Payment Effects] Update Payment Method Loading Finished',

    DeletePaymentMethodLoading = '[Payment Effects] Delete Payment Method Loading',
    DeletePaymentMethodLoadingFinished = '[Payment Effects] Delete Payment Method Loading Finished',

    CancelSubscriptionLoading = '[Payment Effects] Cancel Subscription Loading',
    CancelSubscriptionLoadingFinished = '[Payment Effects] Cancel Subscription Loading Finished',

    LoadSubscriptionsLoading = '[Payment Effects] Load Subscriptions Loading',
    LoadSubscriptionsLoadingFinished = '[Payment Effects] Load Subscriptions Loading Finished',

    LoadPaymentsLoading = '[Payment Effects] Load Payments Loading',
    LoadPaymentsLoadingFinished = '[Payment Effects] Load Payments Loading Finished'
}

export enum CommentsLoadingActionsTypes {
    NewCommentLoading = '[Comments Effects] New Comment Loading',
    NewCommentLoadingFinished = '[Comments Effects] New Comment Loading Finished',

    UpdateCommentLoading = '[Comments Effects] Update Comment Loading',
    UpdateCommentLoadingFinished = '[Comments Effects] Update Comment Loading Finished'
}

export enum SearchLoadingActionsTypes {
    SuggestedContentLoading = '[Search Effects] Suggested Content Loading',
    SuggestedContentLoadingFinished = '[Search Effects] Suggested Content Loading Finished'
}

export enum UsersLoadingActionsTypes {
    UpdateUserLoading = '[Users Effects] Update User Loading',
    UpdateUserLoadingFinished = '[Users Effects] Update User Loading Finished'
}

export enum ReferralLoadingActionsTypes {
    TrackProductSubscribeEventLoading = '[Referral Effects] Track Product Subscribe Event Loading',
    TrackProductSubscribeEventLoadingFinished = '[Referral Effects] Track Product Subscribe Event Loading Finished'
}

export enum NewsLoadingActionsTypes {
    LoadRecentNewsLoading = '[News Effects] Load Recent News Loading',
    LoadRecentNewsLoadingFinished = '[News Effects] Load Recent News Loading Finished',

    LoadCategoryArticlesLoading = '[News Effects] Load Category Articles Loading',
    LoadCategoryArticlesLoadingFinished = '[News Effects] Load Category Articles Loading Finished',

    LoadTrendingArticlesLoading = '[News Effects] Load Categories Loading',
    LoadTrendingArticlesLoadingFinished = '[News Effects] Load Categories Loading Finished',

    LoadCategoriesLoading = '[News Effects] Load Categories Loading',
    LoadCategoriesLoadingFinished = '[News Effects] Load Categories Loading Finished'
}

/**
 * Video Guides loading actions
 */
export const recentVideoGuidesLoading = createAction(
    VideoGuidesLoadingActionsTypes.RecentVideoGuidesLoading
);
export const recentVideoGuidesLoadingFinished = createAction(
    VideoGuidesLoadingActionsTypes.RecentVideoGuidesLoadingFinished
);
export const searchVideoGuidesLoading = createAction(
    VideoGuidesLoadingActionsTypes.SearchVideoGuidesLoading
);
export const searchVideoGuidesLoadingFinished = createAction(
    VideoGuidesLoadingActionsTypes.SearchVideoGuidesLoadingFinished
);

/**
 * Courses loading actions
 */
export const featuredCoursesLoading = createAction(
    CoursesLoadingActionsTypes.FeaturedCoursesLoading
);
export const featuredCoursesLoadingFinished = createAction(
    CoursesLoadingActionsTypes.FeaturedCoursesLoadingFinished
);

export const coursesLoading = createAction(CoursesLoadingActionsTypes.CoursesLoading);
export const coursesLoadingFinished = createAction(
    CoursesLoadingActionsTypes.CoursesLoadingFinished
);

export const enrolledCoursesLoading = createAction(
    CoursesLoadingActionsTypes.EnrolledCoursesLoading
);
export const enrolledCoursesLoadingFinished = createAction(
    CoursesLoadingActionsTypes.EnrolledCoursesLoadingFinished
);

export const courseLoading = createAction(CoursesLoadingActionsTypes.CourseLoading);
export const courseLoadingFinished = createAction(CoursesLoadingActionsTypes.CourseLoadingFinished);

/**
 * Auth loading actions
 */
export const loginUserLoading = createAction(AuthLoadingActionsTypes.LoginUserLoading);
export const loginUserLoadingFinished = createAction(
    AuthLoadingActionsTypes.LoginUserLoadingFinished
);
export const signupUserLoading = createAction(AuthLoadingActionsTypes.SignupUserLoading);
export const signupUserLoadingFinished = createAction(
    AuthLoadingActionsTypes.SignupUserLoadingFinished
);
export const passwordResetInitiateLoading = createAction(
    AuthLoadingActionsTypes.PasswordResetInitiateLoading
);
export const passwordResetInitiateLoadingFinished = createAction(
    AuthLoadingActionsTypes.PasswordResetInitiateLoadingFinished
);
export const passwordResetLoading = createAction(AuthLoadingActionsTypes.PasswordResetLoading);
export const passwordResetLoadingFinished = createAction(
    AuthLoadingActionsTypes.PasswordResetLoadingFinished
);

/**
 * Payment actions
 */
export const newSubscriptionLoading = createAction(
    PaymentLoadingActionsTypes.NewSubscriptionLoading
);
export const newSubscriptionLoadingFinished = createAction(
    PaymentLoadingActionsTypes.NewSubscriptionLoadingFinished
);
export const addPaymentMethodLoading = createAction(
    PaymentLoadingActionsTypes.AddPaymentMethodLoading
);
export const addPaymentMethodLoadingFinished = createAction(
    PaymentLoadingActionsTypes.AddPaymentMethodLoadingFinished
);
export const loadPaymentMethodsLoading = createAction(
    PaymentLoadingActionsTypes.LoadPaymentMethodsLoading
);
export const loadPaymentMethodsLoadingFinished = createAction(
    PaymentLoadingActionsTypes.LoadPaymentMethodsLoadingFinished
);
export const updatePaymentMethodLoading = createAction(
    PaymentLoadingActionsTypes.UpdatePaymentMethodLoading
);
export const updatePaymentMethodLoadingFinished = createAction(
    PaymentLoadingActionsTypes.UpdatePaymentMethodLoadingFinished
);
export const deletePaymentMethodLoading = createAction(
    PaymentLoadingActionsTypes.DeletePaymentMethodLoading
);
export const deletePaymentMethodLoadingFinished = createAction(
    PaymentLoadingActionsTypes.DeletePaymentMethodLoadingFinished
);
export const cancelSubscriptionLoading = createAction(
    PaymentLoadingActionsTypes.CancelSubscriptionLoading
);
export const cancelSubscriptionLoadingFinished = createAction(
    PaymentLoadingActionsTypes.CancelSubscriptionLoadingFinished
);
export const loadSubscriptionsLoading = createAction(
    PaymentLoadingActionsTypes.LoadSubscriptionsLoading
);
export const loadSubscriptionsLoadingFinished = createAction(
    PaymentLoadingActionsTypes.LoadSubscriptionsLoadingFinished
);
export const loadPaymentsLoading = createAction(PaymentLoadingActionsTypes.LoadPaymentsLoading);
export const loadPaymentsLoadingFinished = createAction(
    PaymentLoadingActionsTypes.LoadPaymentsLoadingFinished
);

/**
 * Comments actions
 */
export const newCommentLoading = createAction(CommentsLoadingActionsTypes.NewCommentLoading);
export const newCommentLoadingFinished = createAction(
    CommentsLoadingActionsTypes.NewCommentLoadingFinished
);
export const updateCommentLoading = createAction(CommentsLoadingActionsTypes.UpdateCommentLoading);
export const updateCommentLoadingFinished = createAction(
    CommentsLoadingActionsTypes.UpdateCommentLoadingFinished
);

/**
 * Search actions
 */
export const suggestedContentLoading = createAction(
    SearchLoadingActionsTypes.SuggestedContentLoading
);
export const suggestedContentLoadingFinished = createAction(
    SearchLoadingActionsTypes.SuggestedContentLoadingFinished
);

/**
 * Users actions
 */
export const updateUserLoading = createAction(UsersLoadingActionsTypes.UpdateUserLoading);
export const updateUserLoadingFinished = createAction(
    UsersLoadingActionsTypes.UpdateUserLoadingFinished
);

/**
 * Referral actions
 */
export const trackProductSubscribeEventLoading = createAction(
    ReferralLoadingActionsTypes.TrackProductSubscribeEventLoading
);
export const trackProductSubscribeEventLoadingFinished = createAction(
    ReferralLoadingActionsTypes.TrackProductSubscribeEventLoadingFinished
);

/**
 * News actions
 */
export const loadRecentNewsLoading = createAction(NewsLoadingActionsTypes.LoadRecentNewsLoading);
export const loadRecentNewsLoadingFinished = createAction(
    NewsLoadingActionsTypes.LoadRecentNewsLoadingFinished
);
export const loadCategoryArticlesLoading = createAction(
    NewsLoadingActionsTypes.LoadCategoryArticlesLoading
);
export const loadCategoryArticlesLoadingFinished = createAction(
    NewsLoadingActionsTypes.LoadCategoryArticlesLoadingFinished
);

export const loadTrendingArticlesLoading = createAction(
    NewsLoadingActionsTypes.LoadTrendingArticlesLoading
);
export const loadTrendingArticlesLoadingFinished = createAction(
    NewsLoadingActionsTypes.LoadTrendingArticlesLoadingFinished
);
export const loadCategoriesLoading = createAction(
    NewsLoadingActionsTypes.LoadCategoriesLoading
);
export const loadCategoriesLoadingFinished = createAction(
    NewsLoadingActionsTypes.LoadCategoriesLoadingFinished
);

